<template>
  <section
    id="company"
    class="mb-n5"
  >
    <heading class="hidden-screen-only">
      {{ $route.params.company }}
    </heading>
    <v-container>
      <v-row
        align="center"
        class="mb-5"
      >
        <v-col
          cols="12"
          md="4"
        >
          <v-card outlined>
            <v-img :src="company.logo" />
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="8"
        >
          <h1 class="title text-capitalize">
            {{ company.name }}
          </h1>

          <p class="body-1">
            {{ company.description }}
          </p>
          <p class="body-1">
            <a
              class="blue--text"
              target="_blank"
              :href="company.urlLink"
            >{{ company.url }}</a>
          </p>
        </v-col>
      </v-row>
      <v-row
        align="center"
        class="mb-5"
      >
        <v-col
          cols="12"
          md="12"
        >
          <v-card
            class="mx-auto"

            tile
          >
            <v-list shaped>
              <v-subheader class="title">
                {{ $t("company.product_lines") }}
              </v-subheader>
              <v-list-item-group
                v-model="item"
                color="primary"
              >
                <v-list-item
                  v-for="(product, i) in company.products"
                  :key="i"
                  :to="{ name: 'product', params: {lang:$i18n.locale, company: company.id,product:product.id }}"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="product.name" />
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        align="center"
        class="mb-5"
      >
        <v-col
          cols="12"
          md="12"
        >
          <v-carousel
            class="elevation-1"
            cycle
            light
            show-arrows-on-hover
            height="100%"
          >
            <v-carousel-item
              v-for="(slide, i) in company.images"
              :key="i"
            >
              <v-sheet>
                <v-img
                  contain
                  :src="slide"
                />
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>

      <v-row
        align="center"
        class="mb-5"
      >
        <v-col
          cols="12"
          md="4"
        />
      </v-row>
    </v-container>
  </section>
</template>

<script>
  import i18n from '../i18n'
  export default {

    metaInfo () {
      return {
        title: this.title,

      }
    },
    data: () => ({

      title: 'company',
      loading: false,
      company: null,
      error: null,

    }),
    watch: {
      // call again the method if the route changes
      $route: 'fetchData',
    },
    created () {
      // fetch the data when the view is created and the data is
      // already being observed
      this.fetchData()
    },
    methods: {

      fetchData () {
        this.error = this.post = null
        this.loading = true

        fetch('/api/' + i18n.locale + '/company/' + this.$route.params.company + '.json')
          .then(r => r.json())
          .then(json => {
                  this.loading = false

                  this.company = json
                  this.company.urlLink = 'http://' + this.company.url
                  this.title = json.name
                },
                response => {
                  console.log('Error loading json:', response)
                })
      },
    },

  }
</script>
<style lang="sass">
  #projects
    .v-slide-group__content
      justify-content: center
</style>
